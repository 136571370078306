<template>
    <div class="wrap" v-if="game">
        <div class="header clearfix">
            <p class="rule fl" @click="showRule = true"></p>
            <p class="close-game fr" @click="close">关闭</p>

        </div>
        <div class="main">
            <h2>  余额 <span>{{gold}}</span>,单次抽奖:{{game.price}}</h2>
            <ul class="box">
                <li class="animate1">
                    <img src="~@/assets/card/image/pattern1.png">
                </li>
                <li class="animate2">
                    <img src="~@/assets/card/image/pattern2.png">
                </li>
                <li class="animate3">
                    <img src="~@/assets/card/image/pattern3.png">
                </li>
                <li class="animate4">
                    <img src="~@/assets/card/image/pattern4.png">
                </li>
                <li class="animate5">
                    <img src="~@/assets/card/image/pattern1.png">
                </li>
                <li>
                    <img src="~@/assets/card/image/pattern2.png">
                </li>
            </ul>
        </div>
        <div id="mask-card"></div>
        <rule :content="game['remark']" v-show="showRule" @close="showRule = false"/>
        <prize v-if="showPrize" :prize="prize" @close="reset"/>
    </div>
</template>

<script>
    import prize from "@/components/prize";
    import rule from "@/components/rule";
    import '@/assets/card/css/index.css'
    import {Toast} from "vant";

    export default {
        name: "index",
        components: {
            prize,
            rule
        },
        data() {
            return {
                showRule: false,
                showPrize: false,
                prize: null,
                game: null,
                gold: 0,
                code: 'card',
                inDraw: false,
            }
        },
        created() {
            this.$http.get('lottery-game/info', {params: {code: this.code}}).then((res) => {
                this.game = res.data;
                this.gold = res.data['gold']
                this.$nextTick(() => {
                    this.initJquery()
                })
            })
        },
        methods: {
            reset() {
                this.showPrize = false;
            },
            initJquery() {
                let that = this
                $(function () {
                    let $cards = $(".box li"),//所有卡牌
                        $maskCard = $("#mask-card"),//翻牌遮罩
                        length = $cards.length,
                        index = length,//轮流滚动的卡牌下标
                        rem = 75,
                        initArr = [[34 / rem, 0], [273 / rem, 0], [512 / rem, 0], [34 / rem, 268 / rem], [273 / rem, 268 / rem], [512 / rem, 268 / rem]],//卡牌位置数组
                        clickTime = 0,
                        bool = false,//首次点击时不能在卡牌归位期间
                        timer;//轮流提示定时器

                    //卡牌归位动画
                    for (let i = 0; i < length; i++) {
                        (function (i) {
                            let seat = setTimeout(function () {
                                $cards.eq(i).css({
                                    left: initArr[i][0] + "rem",
                                    top: initArr[i][1] + "rem"
                                });
                                clearTimeout(seat);
                            }, 300 * i);
                        })(i);
                    }

                    //卡牌轮流选中动画
                    setTimeout(function () {
                        timer = setInterval(function () {
                            bool = true;//卡牌归位，可以点击
                            $cards.eq(index - 1).removeClass("active");
                            index %= 6;
                            $cards.eq(index).addClass("active");
                            index++;
                        }, 1000);
                    }, 1000);


                    //点击卡牌翻转
                    $(".box").on("click", "li", function () {
                        if (new Date() - clickTime > 2000 && bool) {//两次点击的间隔不能小于2秒
                            if (parseInt(that.gold) < parseInt(that.game.price)) {
                                return Toast.fail({message: '余额不足', position: 'bottom'})
                            }
                            let requestCompleted = false
                            let animateCompleted = false;
                            that.$http.post('lottery-game/draw', {code: that.code}).then((res) => {
                                that.prize = res.data
                                that.gold = res.data['gold']
                                requestCompleted = true;
                                that.shouldShowPrize(animateCompleted,requestCompleted)
                            });
                            $maskCard.show();

                            $(this).addClass("open-card");//添加翻牌动画

                            //动画监听
                            $(this).on("animationend", function () {
                                $(this).removeClass("open-card");//移除翻牌动画
                                $maskCard.hide();
                                $(this).off("animationend");//解绑动画监听
                                animateCompleted = true
                                that.shouldShowPrize(animateCompleted,requestCompleted)
                            });

                        }
                    });
                });


            },

            shouldShowPrize(a,b){
                if(a && b){
                    this.showPrize = true;
                }
            }

        }
    }
</script>

<style scoped>

</style>
